.country_link
{
    margin-top: 2% important;
}
#home_navbar {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    padding: 2px 20px;
    background: #fff;
    box-shadow: none;
    border-bottom: 1px solid lightgrey;
    z-index:10;
    box-shadow: 0px 2px 4px lightgrey;
}
#main_header_navbar ul {
    padding: 10px !important;  
}
#main_header_navbar .nav-link {
    /* color: #233f78; */
    color:black;    
    transition: color 0.5s;
    cursor: pointer;
}
#main_header_navbar .nav-link:hover, .link_active {
    color:#f37421!important;
}
#home_navbar .form-inline .input-group {
    border-radius:20px;
}
.header_search input.form-control, .header_search input.form-control:focus {
    background: transparent !important;
    color:#44a1a0 !important;
    border:1px solid lightgray;
    border-radius: 20px !important;
    outline:#44a1a0 !important;
    outline-offset: 0px;
    box-shadow: none;
    padding-left: 35px;
    transition: box-shadow 1s;
}
.header_search input.form-control:hover, .header_search input.form-control:focus {
    box-shadow: 0px 2px 4px lightgray;
}
.header_search .input-group>.custom-select:not(:last-child), .header_search .input-group>.form-control:not(:last-child) {
    border-radius: 30px !important;
}
.header_search .input-group-text {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index:4;
    background: transparent;
    border: none;
    color: #233f78;
    justify-content: center;
}

.header_search input::placeholder {
    color: darkgrey;
}

.states_dropdown_content {
    position: fixed;
    top: 50px;
    left: 0px;
    right: 0px;
    background-color: #ffff;
    padding: 20px;
    z-index: 10;
    border-top: 1px solid whitesmoke;
    box-shadow: 0px 2px 10px lightgray;
}
.states_dropdown_content .state_link {
    padding: 0px;
    margin: 2px;
    text-decoration: none;
    color: #233f78;
    transition: margin-left 0.6s, color 0.4s;
    cursor: pointer;
}
.states_dropdown_content .state_link:hover, .state_link:focus {
    margin-left: 10px;
    color: #f37421 !important;
}

.autocomplete_results {
    position: absolute;
    top:40px;
    left: 3%;
    right: 3%;
    width: 94%;
    z-index: 7;
    font-size: 11px;
    background:#ffff;
    box-shadow: 0px 4px 10px lightgrey; 
    border: 1px solid lightgrey;
}
.no-suggestions {
    color: #44a1a0;
    padding: 0.5rem;
}
.suggestions {
    border: 1px solid #0000;
    border-top-width: 0;
    list-style: none;
    max-height: 240px !important;
    overflow-y: scroll;
    padding: 2px;
    margin-bottom: 0px !important;
}
.suggestions li {
    padding: 0.5rem;
}
.suggestion-active, .suggestions li:hover {
    background-color: #44a1a0;
    color: #ffff;
    cursor: pointer;
    font-weight: 700;
}
.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #fff;
}

.dropdown-submenu {
    position: relative;
  }
  
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
  }
  
  @media only screen and (max-width: 600px) {
    .mobile-col{
        /* padding-left: 6% !important; */
    }
  }