.compare_container {
    margin-top: 40px;
    min-height: 40vh;
}
.compare_container .autocomplete_results {
    background: #ffff;
    border: 1px solid lightgray;
    position: absolute;
    top: 45px;
    box-shadow: none;
    box-shadow: 0px 0px 6px lightgray;
}
.compare_container input[type="search"].form-control {
    color: #233f78;
    padding-right: 40px;
    transition: box-shadow 0.5s;
    height: 44px;
}
.compare_container input[type="search"].form-control:focus {
    border: 1px solid lightgray;
    outline: none;
    box-shadow: 0px 2px 6px lightgray;
}
.compare_container .input-group-text {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 12px;
    background: transparent;
}

.compare_container .sub_header {
    color: #ffff;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
}
.compare_container p {
    color: #233f78;
    font-size: 15px;
    margin: 4px;
}
.compare_container .compare_cat ul {
    list-style-type: none;
    padding: 4px 10px;
}
.compare_container .course_header {
    padding: 8px 4px;
    color: #f37421;
    font-weight: 600;
}
.compare_cat ul > li {
    line-height: 2;
}
.compare_cat ul > li .course_check {
    margin-right: 6px;
    margin-top: 2px;
}
.compare_container .small_text {
    color: #172a3a;
    text-decoration: underline;
}
.compare_container .compare_cat {
    padding: 4px;
    color: #233f78;
    border: 1px solid lightgrey;
}
.compare_container .compare_cat i {
    font-size: 18px;
}
.compare_container .college_header {
    position: sticky;
    top: 66px;
    z-index: 10;
    padding: 10px;
    min-height: 80px;
    box-shadow: 0px 0px 4px black;
    background: #44a1a0;
    border: 1px solid white;
}
.compare_container .compare_cat a {
    color: #ffff;
}
.compare_container .course_check {
    cursor: pointer;
    margin-top: 14px !important;
}
.compare_container .course_check i.fa {
    font-size: 15px;
}
.compare_container .course_check[aria-expanded="true"] i.fa-plus {
    display: none;
}
.compare_container .course_check[aria-expanded="false"] i.fa-minus {
    display: none;
}
.compare_container .fav_icon {
    position: absolute;
    top: -20px;
    right: -14px;
    font-size: 24px !important;
    background: #ffff;
    padding: 6px;
    box-shadow: 0px 0px 10px lightgrey;
}
