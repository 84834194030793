
.predictor_page{
  /* height: 100vh !important; Ensure full height */
  overflow-y: auto !important; /* Enable vertical scrolling */
  overflow-x: hidden !important;
}
.predictor-container {
    height: 100vh;
    background-color: #fae9e5; /* Light pink background as in the image */
  }
  .border_radius{
    border-radius: 10px !important;
    border:1px solid black !important;
  }
  .predictor-card {
    border-radius: 20px !important;
    padding: 30px 0px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-right:10% ;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #233974; /* Dark blue color for the header */
  }
  
  input, select {
    font-size: 14px;
  }
  
  img {
    max-width: 100%;
    border-radius: 15px;
  }
  .predictor_submit_button{
    width:50% !important;
  }
  .predictor_lable{
    color:#1d3d79;
    font-size:13px;
  
  }
  .predictor_heading{
    margin-bottom: 7% !important;
  }
  @media only screen and (max-width: 580px) {
    .predictor-card{
      padding:0px;
      margin-right:0% !important ;
    
    
    }
    .predictor_second_div{
      margin-bottom:20%;
      padding: 0% 10% !important;
    }
    .predictor_doctor_image_position{
      display: none !important;
      
    }
    .predictor_submit_button{
      width:60% !important;
    }
    
    .predictor_heading{
      font-size: 20px;
      font-weight: bold !important;
      margin-top :5%;
    }
  }