.checkout_container {
    margin: 30px auto;
}
.checkout_container .header {
    color: #233f78;
}
.checkout_container .pricing_package {
    background: #ffff;
    box-shadow: 4px 4px 10px lightgray;
}
.checkout_container .checkout_package {
    color: #233f78;
}
.checkout_container .package_header {
    padding: 20px 5px;
    color: #ffff;
    background: #44a1a0;
}
.checkout_container .package_body {
    padding: 15px 15px 15px 30px;
    background: #ffff;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}
.checkout_container .check_icon {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
    height: 77px;
    background: white;
    border-radius: 50%;
    padding: 8px;
}
.checkout_container .benefit_true i {
    color: #2ec4b6;
}
.checkout_container .benefit_false i {
    color: #bd2426;
}
.checkout_container .benefit_false {
    text-decoration: line-through;
}
.checkout_container .abroad_btn {
    box-shadow: none;
    width: 100%;
    padding: 10px;
}
.checkout_container .cancel_btn {
    background: #44a1a0;
}
.checkout_container .header {
    color: #2ec4b6;
}
.checkout_container .actual_price {
    color: #ffff;
}
.checkout_container .offer_banner {
    height: 50px;
}
.remove_cart_btn {
    position: absolute;
    top: -15px;
    right: -15px;
    height: 40px;
    width: 40px;
    color: #bd2426;
    border-radius: 20px;
    z-index: 2;
    background: #ffff;
    padding: 10px;
    box-shadow: 0px 0px 10px lightgray;
}
.checkout_container .coupon_btn {
    height: 38px !important;
    padding: 8px 20px !important;
}
