/* todo: delete file if not needed */
.interested_side_container {
    background: linear-gradient(to bottom, #44a1a0, #172a3a);
    padding: 20px;
    color: #ffff;
}
.interested_side_container .form-control {
    color: #172a3a;
}
.interested_side_container .abroad_btn {
    border: 2px dotted #ffff;
}
