/* todo: delete file if not needed */
.message_error_container {
    position: fixed;
    bottom: 4vh;
    right: 10px;
    left: 10px;
    padding: 4px;
    z-index: 2;
    text-align: center;
}
.message_error_container span {
    color: #ffff;
    padding: 10px 20px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    border: 1px dotted white;
}
.message_text {
    background-color: #44a1a0;
}
.error_text {
    background-color: #b23a48;
}
.min_height {
    min-height: 66vh !important;
}
.login_modal_container {
    /* align-items: center; */
    justify-content: center;
    background: transparent;
}
.login_icon {
    border-radius: 50%;
    border: 4px dotted #ffff;
    margin: 10px;
}
.login_container {
    position: fixed !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 12;
    width: auto;
    text-align: center;
    padding: 20px;
    color: #ffff;
    min-width: 300px;
    box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.8);
    /* border-left: 1px solid #ffff; */
    background: linear-gradient(to bottom, #172a3a, #233f78) !important;
}
.login_container h6 {
    color: #ffff;
}
.login_modal_container .form-control,
.login_modal_container .form-control:focus {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    border: 0.1px solid whitesmoke;
    box-shadow: none;
    border-radius: 0px;
    max-width: 300px !important;
}
.login_modal_container .form-control::placeholder {
    color: whitesmoke;
}
.login_modal_container .input-group-text {
    background: #172a3a;
    color: #ffff;
    border-radius: 0px;
    width: 40px;
    justify-content: center;
}

/* Side callback container */
.main_callback_container {
    align-items: center;
    justify-content: center;
    background: transparent;
}
.callback_icon {
    border-radius: 50%;
    border: 4px dotted #ffff;
    margin: 10px;
}
.callback_container {
    position: fixed !important;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 10;
    width: auto;
    text-align: center;
    /* padding:20px; */
    color: #ffff;
    min-width: 300px;
    box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.8);
    /* border-left: 1px solid #ffff; */
    background: linear-gradient(to bottom, #44a1a0, #172a3a) !important;
}
.callback_container h6 {
    color: #ffff;
}
.main_callback_container .form-control,
.main_callback_container .form-control:focus {
    color: #172a3a;
    outline: none;
    border: none;
    border: 0.1px solid whitesmoke;
    box-shadow: none;
    border-radius: 0px;
    max-width: 300px !important;
}

input:-webkit-autofill,
input:-webkit-autofill-strong-password {
    background-color: white !important;
}

.menu_close_btn {
    /* float: right; */
    position: absolute;
    top: -10px;
    right: 10px;
    cursor: pointer !important;
}
.login_content_section {
    background: transparent;
    color: white;
    border-right: 1px solid #44a1a0;
}
.login_btn {
    background-color: #233f78;
    color: #ffff !important;
    border: 1px solid white;
    padding: 6px 20px;
    box-shadow: none;
    font-size: 14px;
    transition: background-color 1s, color 1s, box-shadow 1s;
}
.login_btn:hover {
    background-color: #f37421;
    box-shadow: 2px 2px 4px black;
}
.abroad_btn {
    padding: 5px 15px;
    color: #ffff;
    border: none;
    box-shadow: none;
    background-color: #172a3a;
    transition: background-color 1s, box-shadow 1s, color 1s, border 1s;
}
.abroad_btn:hover,
.abroad_btn:focus {
    background-color: #44a1a0;
    outline: none !important;
    /* box-shadow: 2px 2px 4px black; */
}
.abroad_btn.user_link {
    width: 90%;
    margin: 10px auto;
    display: block;
    padding: 8px;
    border: 1px dotted #ffff;
    transition: background-color 1s;
}
.abroad_btn.user_link:hover,
.abroad_btn.user_link_active {
    color: #ffff;
    background-color: #44a1a0;
}
.breadcrumb {
    background: transparent !important;
    padding: 2px 5px !important;
}
.breadcrumb-item a {
    color: #233f78 !important;
}
.breadcrumb-item a:hover,
.breadcrumb-item {
    font-size: 13px !important;
    color: #f37421 !important;
}
.breadcrumb > li + li:before {
    content: "\3E" !important;
}
.up_arrow {
    position: fixed;
    border: 2px solid #ffff;
    bottom: 30px;
    right: 30px;
    color: #ffff;
    border-radius: 50%;
    background: #44a1a0;
    padding: 10px;
    z-index: 5;
}
.main_header {
    font-size: 36px;
    color: #f37421;
    margin-bottom: 5px;
    font-family: "Alegreya Sans SC", sans-serif;
}
.sub_header {
    font-size: 18px;
    color: #233f78;
}
.hori_line {
    height: 2px;
    margin-top: 0px;
    width: 100px;
    background: linear-gradient(to left, #172a3a, #44a1a0);
}
.popular_section {
    background: white;
}
.popular_section .card {
    box-shadow: 4px 4px 10px 2px lightgrey;
    color: #ffff;
    transition: margin-top 1s, box-shadow 1s;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}
.popular_section .card:hover {
    margin-top: -10px;
    box-shadow: 6px 6px 10px 2px lightgrey;
}
.fav_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    padding: 8px;
    z-index: 5;
    font-size: 22px !important;
    cursor: pointer;
    border: 2px solid#fff;
    background: rgba(0, 0, 0, 0.1);
    color: rgb(255, 0, 0.8);
}
.col_details {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 5;
    text-align: left;
    font-weight: 600;
}
.popular_icon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
    background: #f37421;
    border-radius: 20px;
    border: 2px solid #ffff;
    padding: 5px 10px;
    color: #ffff;
}
.interested_container {
    background: linear-gradient(to right, #44a1a0, #172a3a);
    color: #ffff;
}
.interested_container .abroad_btn {
    border: 1px dotted white;
}
.interested_container .main_header {
    color: #ffff;
}
.bg_overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.8);
    /* background: linear-gradient(to right,  #f37421, #f37421, #233f78, #233f78);  */
    opacity: 0.2;
}
.card .bg_overlay {
    opacity: 0.7;
}
.check_label {
    margin-left: 5px;
    color: #233f78;
    font-size: 14px;
}
/* todo: delete file if not needed */
.filter_header {
    padding: 10px;
    border: none;
    color: #f37421;
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
}
.filters_card input {
    cursor: pointer;
}
.filter_sub_header {
    padding: 6px;
    font-size: 14px;
    color: #f37421;
    text-transform: uppercase;
    border-bottom: 1px solid lightgray;
}
.filter_selector {
    padding: 6px;
    border: 1px solid lightgrey;
    /* box-shadow: 0px 2px 4px lightgray; */
    border-radius: 5px;
    margin-bottom: 10px;
}
.filter_header i {
    float: right;
    padding-top: 5px;
    font-size: 14px;
}
.clear_btn {
    border: 1px solid #b23a48;
    color: #b23a48;
    padding: 4px 8px;
    font-size: 13px;
    background-color: transparent;
    transition: background-color 1s, color 0.5s, border 0.5s;
}
.clear_btn:hover {
    border: 1px solid #9e0031;
    background-color: #9e0031;
    color: #ffff;
}
.filter_div {
    padding: 6px;
    text-align: left;
    border-bottom: 1px solid lightgray;
}
.filters_card {
    border: 1px solid lightgrey;
    box-shadow: 0px 2px 6px lightgray;
    /* position: sticky;
    top:10vh; */
}
.filter_div select::before,
.filter_div select.form-control {
    border-radius: 0px !important;
    color: #233f78;
}
.filter_div .form-control {
    height: 40px;
    color: #233f78;
}
img.filter_loader {
    margin-top: 6vh;
    min-height: 300px;
}
.no_results {
    color: #f37421;
}

.filter_div input.form-control,
.filter_div input.form-control:focus {
    background: transparent !important;
    color: #233f78 !important;
    border: 1px solid lightgray;
    border-radius: 20px !important;
    outline: #233f78 !important;
    outline-offset: 0px;
    box-shadow: none;
    padding-left: 30px;
    transition: box-shadow 1s;
}
.filter_div input.form-control:hover,
.filter_div input.form-control:focus {
    box-shadow: 0px 0px 6px 1px lightgray;
}
.filter_div .input-group > .custom-select:not(:last-child),
.filter_div .input-group > .form-control:not(:last-child) {
    border-radius: 20px !important;
}
.filter_div .input-group-text {
    position: absolute;
    top: 4px;
    left: 3px;
    bottom: 4px;
    z-index: 4;
    background: transparent;
    border: none;
    color: #233f78;
}
.popular_div,
.mci_div,
.fmge_div {
    padding: 3px 6px;
    font-size: 13px;
    margin: 5px;
    font-weight: 500;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.8);
}
.popular_div {
    background: #233f78;
}
.mci_div {
    background: #f37421;
}
.fmge_div {
    background: #17a2b8;
}

.autocomplete_card {
    position: absolute;
    top: 40px;
    padding: 10px;
    background: #ffff;
    color: #233f78;
}
ul.pagination {
    float: right;
}
ul.pagination li {
    padding: 8px 13px;
    background: #ffff;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
ul.pagination a {
    color: #233f78;
}
ul.pagination li.active {
    background: #233f78;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.6);
}
ul.pagination li.active a {
    color: #ffff !important;
}
ul.pagination li.disabled {
    opacity: 0.5;
}
.small_text {
    font-size: 12px;
    font-weight: 400;
}
.medium_text {
    font-size: 14px;
    white-space: pre-wrap;
}
.date_text {
    font-size: 14px;
    color: #f37421;
}
label.small_text {
    margin-bottom: 3px;
}
.text_blue {
    color: #233f78 !important;
}
.bg-black {
    background-color: #172a3a !important;
}
.cart_icon {
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 10;
    height: 60px;
    width: 60px;
    background-color: #233f78;
    border: 1px solid white;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0px 0px 6px grey;
}
.cart_icon i {
    font-size: 30px;
    color: #ffff;
}
.cart_counter {
    position: absolute;
    top: -5px;
    left: -5px;
    height: 20px;
    width: 20px;
    background: #fff;
    color: #233f78;
    border-radius: 50%;
    border: 1px solid #f37421;
}

@media screen and (max-width: 768px) {
}
