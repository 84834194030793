.otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  h3 {
    color: #233974;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
  
  h2 {
    color: #233974;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }
  
  .otp-input-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 8px;
    text-align: center;
    font-size: 24px;
    border: none;
    border-bottom: 2px solid #f47523;
    background-color: transparent;
    outline: none;
    color: #233974;
    font-weight: bold;
  }
  
  .otp-input:focus {
    border-bottom: 3px solid #f47523;
  }
  
  .otp-resend {
    text-align: center;
    color: #f47523;
    cursor: pointer;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .otp-submit-btn {
    background-color: #f47523;
    color: white;
    border: none;
    padding: 12px 40px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }
  
  .otp-terms {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }
  
  .otp-terms span {
    color: #233974;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .otp-message {
    color: green;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
  }
  