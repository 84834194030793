.edit_profile_container {
    margin: 30px auto;
}
.edit_profile_container .header {
    color: #233f78;
}
.edit_profile_container .sub_header {
    color: #f37421;
    font-size: 24px;
    font-weight: 500;
}
.edit_profile_container .para {
    color: #233f78;
}
.edit_profile_container [type="radio"] {
    opacity: 0;
    height: 0px;
}
.edit_profile_container [type="radio"] + img {
    cursor: pointer;
    border-radius: 50%;
    opacity: 0.6;
    box-shadow: none;
    border: 5px solid transparent;
    transition: box-shadow 1s, border 1s, opacity 1s;
}
.edit_profile_container [type="radio"]:checked + img {
    box-shadow: 0px 0px 10px 4px lightgrey;
    border: 5px solid white;
    opacity: 1;
}
.edit_profile_container .change_password,
.edit_profile_container .edit_profile {
    box-shadow: 0px 4px 10px lightgray;
    padding: 20px;
}
.edit_profile_container .form-control {
    color: #233f78;
}
.edit_profile_container .small_text {
    color: #172a3a;
    float: left;
}
.edit_profile_container .abroad_btn {
    padding: 8px 20px;
}
.change_password .show_hide_pwd {
    font-size: 30px;
    color: #233f78;
    padding: 4px 10px;
    /* color: #ffff; */
}
