.filter_results .college_card {
    border: 1px solid lightgrey;
    border-radius: 0px;
    text-align: left;
    box-shadow: 0px 2px 4px lightgrey;
    margin-bottom: 15px;
    transition: box-shadow 1s;
}
.filter_results .college_card:hover {
    box-shadow: 0px 4px 10px 2px lightgrey;
}
.filter_results .college_card h5 {
    color: #233f78;
    margin-bottom: 2px;
}
.filter_results .college_card .small_text {
    margin: 2px;
    color: #233f78 !important;
}
.filter_results .college_card img {
    height: 100px;
    width: 100px;
}
.filter_results .college_card .accr_name {
    font-size: 12px;
}
.filter_results .fav_icon {
    padding: 0px;
    top: 0px;
    right: 0px;
    background: none;
    font-size: 24px !important;
}
.filter_results .abroad_btn {
    border-radius: 0px;
    font-size: 13px;
    padding: 3px 6px;
    box-shadow: none;
    border: 1px solid black;
}
.filter_results .course_container {
    margin-top: 10px;
    border-top: 1px solid lightgray;
    padding: 10px;
    color: #233f78;
}
.filter_results .course_container p {
    font-size: 13px;
    display: flex;
    margin-bottom: 2px;
}
.filter_results .course_container p i {
    margin: 3px 5px 0px 0px;
}
