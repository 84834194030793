.pricing_container {
    margin: 30px auto;
}

.animate-pulse
{
    animation:pulse 4s infinite;
}
.pricing_container .header {
    color: #233f78;
}
.pricing_container .pricing_package {
    background: #ffff;
    box-shadow: 4px 4px 10px lightgray;
}
.pricing_container .package_header {
    padding: 20px 5px;
    color: #ffff;
    background: #44a1a0;
}
.pricing_container .package_body {
    padding: 15px 15px 15px 30px;
    background: #ffff;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}
.pricing_container .popular_banner {
    position: absolute;
    top: -30px;
    right: -6px;
    z-index: 2;
    height: 66px;
}
.pricing_container .offer_banner {
    height: 50px;
}
.pricing_container .benefit_true i {
    color: #2ec4b6;
}
.pricing_container .benefit_false i {
    color: #bd2426;
}
.pricing_container .benefit_false {
    text-decoration: line-through;
}
.pricing_container .abroad_btn {
    box-shadow: none;
    width: 50%;
    padding: 10px;
}
.pricing_container .actual_price {
    text-decoration: line-through;
    color: #ffff;
    font-size:20px;
}
