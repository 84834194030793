#contact_container .contact_form {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
    border: 1px solid whitesmoke;
}
#contact_container div.contact {
    color: #ffff;
    font-weight: 800;
}
#contact_container .bg_overlay {
    opacity: 0.4;
    background: #172a3a;
}
#contact_container hr {
    background: white;
}
#contact_container .social {
    padding: 0px;
}
#contact_container .social li {
    display: inline;
    margin: auto 10px;
}
#contact_container .social a {
    font-size: 24px;
    color: #233f78;
    transition: color 1s;
}
#contact_container .social a:hover {
    color: #2ec4b6;
}
#contact_container .main_header {
    color: #f37421;
}
#contact_container .header {
    color: #233f78;
}
#contact_container img {
    height: 60px;
    margin-bottom: 10px;
}
#contact_container .sub_header {
    color: #44a1a0;
    font-size: 18px;
    font-weight: 600;
}
#contact_container p.para {
    font-size: 14px;
    color: #233f78;
}
#contact_container .branch_div {
    padding: 20px;
}
#branch_offices p.para {
    font-size: 13px;
}
