.upgrade-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f6f8fc;
}

.upgrade-content {
    text-align: center;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
}

.upgrade-content p {
    font-size: 18px;
    color: #012269;
}

.upgrade-content strong {
    font-weight: bold;
}

.offer-box {
    background-color: #ffeae3;
    padding: 30px;
    border-radius: 15px;
    display: inline-block;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.discount {
    font-size: 36px;
    color: #012269;
    margin-bottom: 10px;
}

.limited-offer {
    font-size: 14px;
    color: #7d7d7d;
    margin-bottom: 20px;
}

.price {
    font-size: 42px;
    font-weight: bold;
    color: #012269;
}

.current-price {
    font-size: 42px;
    color: #012269;
}

.original-price {
    text-decoration: line-through;
    font-size: 18px;
    color: #999;
    margin-left: 10px;
}

.tax-info {
    font-size: 14px;
    color: #7d7d7d;
    margin: 10px 0;
}

.upgrade-btn {
    background-color: #f89a27;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.upgrade-btn:hover {
    background-color: #d87f1d;
}
.modal-content{
    border-radius: 15px !important;
}
@media (max-width: 600px) {
    .offer-box {
        padding: 20px;
    }

    .discount {
        font-size: 28px;
    }

    .price {
        font-size: 32px;
    }

    .current-price {
        font-size: 32px;
    }

    .original-price {
        font-size: 16px;
    }
}
