.logo_predictor{
    width:183px;
    height:181px;
}
.doctors_group{
    width: 836px;
    height:547px;
}
.college_benefits {
    list-style: none; /* Remove default bullets */
    padding-left: 40px; /* Adjust space for the image */
}
.background{
    background:#FFEEE6;
}
.rounded-border{
    border-radius: 15px;
}
.college_benefits li {
    background-image: url('../../assets/predictor_list.png');
    background-repeat: no-repeat;
    background-position: 0 1px; /* Adjust the position of the image */
    background-size: 20px 20px; /* Set the size of the image */
    padding-left: 30px; /* Space between the image and the text */
    margin-bottom: 10px; /* Space between list items */
    color:white;
    font-weight: bold;
}
.blue-color{
    color:#233974 !important;
}
.border-color{
    border:1.5px solid #000000b3 !important;
}
.borderradius{
    border-radius: 10px !important;
}
.bg-orange{
    background-color:#233974;
}
.logo_predictor{
    margin-left: 5%;
}
.college_benefits{
    margin-left: 4%;
}
.heading-info{
    margin-left:6%;
}
.logo_predictor_mobile{
    display: none;
}
@media (max-width: 600px) {
    .predictor_login_form{
        padding: 0 10% !important;
    }
    .logo_predictor_mobile{
        display: block;
    }
    .college_benefits{
        display: none;
    }
    .doctors_group{
        display: none;
    }
    .logo_predictor{
        display: none;
        
    }
    .college_benefits{
        margin-left: 1%;
    }
    .heading-info{
        /* margin-left:1%; */
        display: none;
    }
    
}