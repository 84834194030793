.predictor_benefits_desktop_view{
    display: block;
}
.predictor_benefits_mobile_view{
    display: none;
}
.collegebenefits{
    list-style-type: none;
}

.collegebenefits li {
    background-image: url('../../assets/predictor_list.png');
    background-repeat: no-repeat;
    background-position: 0 1px; /* Adjust the position of the image */
    background-size: 15px 15px; /* Set the size of the image */
    padding-left: 20px; /* Space between the image and the text */
    margin-bottom: 10px; /* Space between list items */
    font-size: 12px;
    font-weight: bold !important;
}
@media (max-width: 600px) {
    .predictor_benefits_desktop_view{
        display: none !important;
    } 
    .predictor_benefits_mobile_view{
        display: block;
    }
}