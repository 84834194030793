.rank_predictor_title
{
    font-size: 2rem;
}
.rank_main_div
{
    margin-top:10%;
    margin-bottom: 5%;
}
.animate_image
{
    animation:pulse 2s infinite; /* referring directly to the animation's @keyframe declaration */
    
}
.animate_button
{
    animation:jello 2s infinite;
}
.padding-inside
{
    padding:2% 0% !important;
}
@media (max-width: 768px) {
    .rank_predictor_title
    {
        font-size: 1rem;
        margin-top: 20%;
    }  
}