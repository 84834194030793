@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans+SC&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&family=Roboto:wght@100;400&display=swap');

.bg-dark-blue {
    background-color: #1a2a56; /* Adjust to match your specific dark blue */
  }
  .home_logo{
    float:right;
        width: 186px ;
        height:186px ;
      
  }
  .text-highlight {
    color: #ff6600;
  }
  
  .offer-card {
    position: relative;
    border: 1px solid #ddd;
  }
  
  .ribbon {
    position: absolute;
    top: 0;
    right: 0;
    background: #c40000;
    color: #fff;
    padding: 5px 10px;
    font-size: 0.8rem;
    transform: rotate(45deg);
    transform-origin: top right;
  }
  
  .price-text {
    font-size: 1rem;
    color: #333;
  }
  
  .price {
    font-size: 2rem;
    font-weight: bold;
    color: #ff6600;
  }
  img {
    border-radius: 0%;
  }
  .list-unstyled li {
    background-image: url(/static/media/predictor_list.55a2c3bf.png);
    background-repeat: no-repeat;
    background-position: 0 11px;
    background-size: 30px 30px;
    padding-left: 45px;
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: bold !important;
    padding-top: -6px;
}
.circle-step {
    background-color: #f2762e;
    color: white;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50%;
    width: 80px; /* Increase the circle width */
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .flowchart-text {
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  
  .flowchart-arrow {
    color: #f2762e;
    width: 100px;
  height: auto;
  }
  
  .container {
    background-color: #fef1ec;
    border-radius: 10px;
    padding: 30px;
  }
  
.flowchart-arrow-down {
    width: 50px;
    height: auto;
  }
  
  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
  }
  .home_start_desktop_button{
    display: inherit;
  }
  .home_start_mobile_button{
    display: none;
  }
  .home_image_banner{
    object-fit: contain;
  }
  .tutorial_header{
    text-align: left;
    width: 60% !important;
    margin-left: 18%;
    padding-left: 2%;
    font-size:30px;
    padding-top: 2%;

    /* padding-left: 5%; */
    /* font-size: 15px !important;
    margin: 0 auto; */
}

.tutorial_header_second_one{
    width: 55% !important;
    /* margin: 0 auto; */
    margin-left: 24%;
    padding-left: 4%;
    font-size:25px;
    padding-top: 2%;
    
}
.predictor_feature_button{
    width: 25%;
}
.predictor_feature{

    margin-left: 15%;
    margin-top: 5%;
}
.predictor_features_heading{
    font-weight: bold !important;
  }

  

.iframe{
    margin-top:8%;
    border-radius:20px;
    height:400px;
}
  
  
.rank-link
{
    position:absolute;
    right: 30%;
    bottom: 10%;
}
.properties_font{
    font-size: 0.9rem;
}
.predictor_blue_color{
    color:#233974;
}
.predictor_bg_blue_color{
    background-color: #233974;
}
/* todo: delete file if not needed */
.home_bg_img {
    height: 100vh !important;
    width: 100%;
}
.explore_container {
    /* background-image: linear-gradient(to right, #44a1a0, #172a3a); */
    background-color: #ffe5d6;
}
.border-radius
{
    border-radius: 1.2rem !important;
}
.padding-for-top
{
    padding: 10%;
}
.home_banner_content {
    margin: auto;
    z-index: 6;
}
h2{
    font-family: 'Poppins', sans-serif !important;
}
.our-services-desktop h3 p div
{
    font-family: 'Poppins', sans-serif !important;
    
}
.our-services-desktop  p
{
    font-size: 18px;
    
}
.home_banner_content .search_header {
    color: #44a1a0 !important;
}

.home_banner_content h6 {
    color: #233f78 !important;
    font-weight: 400;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.8);
}
.home_search_group .form-control,
.home_search_group .form-control:focus {
    width: 100%;
    padding-left: 20px !important;
    padding-right: 10% !important;
    margin: 0px !important;
    border-radius: 40px !important;
    font-size: 20px !important;
    color: #44a1a0 !important;
    height: 55px;
    border: 1.5px solid lightgrey;
    box-shadow: 0px 2px 6px lightgrey;
    transition: box-shadow 1s;
}
.home_search_group .form-control:hover,
.home_search_group .form-control:focus {
    box-shadow: 0px 4px 16px lightgrey;
}
.home_search_group .input-group > .custom-select:not(:last-child),
.home_search_group .input-group > .form-control:not(:last-child) {
    border-radius: 40px !important;
}
.home_search_group .input-group-text {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1 !important;
    font-size: 4vh;
    padding-left: 3vh;
    padding-right: 3vh;
    background: #172a3a;
    color: #fff;
    border: 1px solid #ffff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    cursor: pointer;
    transition: background 1s;
}
.home_search_group .input-group-text:hover {
    background: #44a1a0 !important;
}
.home_search_group .form-control::placeholder {
    color: lightgrey !important;
}
.home_state_select {
    min-width: 200px;
    height: 50px;
    border-radius: 30px;
    border: 1px solid lightgrey;
    color: #233f78;
    box-shadow: 0px 2px 6px lightgrey;
}
.home_service_container {
    /* background-image: linear-gradient(to right, #44a1a0, #172a3a); */
}
.home_service_container .header {
    color: #ffff;
}
.home_service_container .card {
    background-color: #ffff;
    /* border:1px solid lightgrey; */
    color: #172a3a;
    /* box-shadow: 2px 4px 10px lightgrey; */
    border-radius: 10px;
    cursor: pointer;
    box-shadow: none;
    transition: margin-top 1s, background-color 1s, color 1s, box-shadow 1s;
}
.home_service_container .card:hover {
    color: #44a1a0;
    margin-top: -8px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.6);
}
.home_service_container .card p {
    font-size: 13px;
    color: #233f78;
}
.home_service_container .card span {
    margin-top: 10px;
    font-size: 24px;
}
.down_arrow {
    position: absolute;
    border: 1px solid #ffff;
    bottom: 20px;
    left: 47.7%;
    border-radius: 50%;
    background: black;
    padding: 10px 12px;
    z-index: 5;
    animation: flash 5s infinite;
}

.home_search_group .autocomplete_results {
    position: absolute;
    top: 58px;
    left: 3%;
    right: 3%;
    width: 94%;
    z-index: 11 !important;
    margin: auto;
    text-align: left;
    font-size: 13px;
    background: white;
    border: 1px solid darkgrey;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.6); */
}
.home_search_group .no-suggestions {
    color: #172a3a;
    padding: 0.5rem;
}
.home_search_group .suggestions {
    border: 1px solid #0000;
    border-top-width: 0;
    list-style: none;
    max-height: 240px !important;
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 0px !important;
}
.home_search_group .suggestions li {
    padding: 0.5rem;
}
.home_search_group .suggestion-active,
.home_search_group .suggestions li:hover {
    background-color: #44a1a0;
    color: #ffff;
    cursor: pointer;
    font-weight: 700;
}
.home_search_group .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid whitesmoke;
}
.mci_fmge_group {
    position: absolute;
    top: 10px;
    left: 10px;
}
.mci_fmge_group .fmge_div,
.mci_fmge_group .mci_div {
    font-size: 14px;
    font-weight: 600;
}
#popular_colleges_section {
    background-color: whitesmoke;
}
.promo_video {
    z-index: 20 !important;
}
.exam_dates_table th {
    background-color: #233f78;
    color: #ffff;
    font-size: 24;
    font-weight: 700;
    text-align: center;
    vertical-align: middle !important;
}
.exam_dates_table tr {
    color: #233f78;
    font-size: 24;
    font-weight: 500;
    text-align: center;
}
.exam_dates_table td {
    vertical-align: middle !important;
}
.cutoffScoreText {
    color: #f37421;
    font-size: 16px;
    font-weight: 800;
}

.college_searc_btn
{
    padding: 0.75rem 2.14rem !important ;
    border-radius:20px !important;
    position:relative !important;
    left:-125px !important;
    background:#f47523 !important;
    color:white !important;
}


.our-services-mobile
{
display:none !important;
}
.our-services-desktop
{
    display: flex;
}

.news-btn
{
    z-index: 999999999 !important;
}
.card {
    font-weight: 400;
    border: 0;
    /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
    /* border: 1px solid #f47523 !important; */
}
.predictor_why_our_tool_desktop{
    display: flex;
}
.predictor_why_our_tool_mobile{
    display: none !important;
}
.predictor_benfits_head_desktop{
    display: block;
  }
  .predictor_benfits_head_mobile{
    display: none;
    /* font-weight: bold; */
  }
  .feature_doctor_mobile{
    display: none;
  }
  .feature_list_img{
    text-align: right;
}
.feature_list_text{
    padding-left: 2%;
}
.pricing_heading{
    /* font-size: 30px !important; */
   margin-left: 8%;
}
.pricing_sub_heading{
    /* font-size: 20px !important; */
    margin-top: 4%;
    margin-left: 8% !important;
    font-size: 45px;
}
.list-unstyled{
    margin-left: 8%;
}
.limited_opporturnities{
    padding:5% 0 ;
}
.pricing_button{
    width: 25%;
}
.faq_question{
    /* font-size: 20px; */
    margin-top: 2%;
}
.faq_description{
    font-size:20px !important;
    
}
.faq-padding{
    padding: 4%;
}
.why_out_tool_desc{
    margin: 0 10% !important;
}
.features_margin_right{
    padding-right: 5% !important;
}
@media (max-width: 768px) {
    .features_margin_right{
        padding-right: 0%;
    }
    .flowchart-arrow {
      display: none;
    }
  }
  .fillout_button{
    width: 25%;
    padding: 1%;
  }
  .predictor_steps_div{
    display: none !important;
}
.home-text{
    padding: 0 5%;
}
.home-text-desc{
    padding: 0 5%;
}
.doubts_fill_out_header{
    font-size:40px;
}
.doubts-description{
    margin: 0 10%;
}
  @media (max-width: 500px) {
    .predictor_steps_div{
        display: flex !important;
    }
    .features_top{
        display: none;
    }
    .predictor_steps_desktop{
        display: none !important;
    }
    .fillout_button{
        width: 100%;
        padding: 1%;
    }
    .doubts_fill_out_header{
        font-size: 20px;
    }
    .faq_header{
        font-size: 25px !important;
    }
    .doubts-header{
        font-size: 25px !important;
    }
    .doubts-description{
        font-size: 12px !important;
        margin:0 0 ;
    }
    .faq-padding{
        padding: 5%;
    }
    .faq_question{
        font-size: 14px;

    }
    .faq_description{
        font-size:10px !important;
    }
    .pricing_button{
        width: 75% !important;
        border-radius: 15px !important;
    }
    .limited_opporturnities{
        padding:5% 0 ;
    }
    .list-unstyled{
        margin-left: 0;
        padding:0 3%;
    }
    .list-unstyled li {
        background-image: url(/static/media/predictor_list.55a2c3bf.png);
        background-repeat: no-repeat;
        background-position: 22px 4px !important;
        background-size: 11px 11px !important;
        padding-left: 45px;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: bold !important;
        padding-top: -6px;
    }
    .pricing_heading{
        font-size: 30px !important;
        text-align: center;
        margin-left: 0 !important;
    }
    .pricing_sub_heading{
        font-size: 20px !important;
        text-align: center;
        margin-left: 0 !important;
    }
    .feature_list_img{
        text-align: center;
    }
    .feature_list_text{
        padding-left: 0;
    }
    .feature_doctor_mobile{
        display: flex;
    }
    .feature_doctor_desktop{
        display: none;
    }
  .predictor_benfits_head_desktop{
    display: none;
  }
  .predictor_benfits_head_mobile{
    display: block;
    font-weight: bold;
  }
      .home_logo_div{
        text-align: center !important;
      }
    .why_out_tool_desc{
        font-size: 12px !important;
        text-align: center;
    }
    .why_out_tool_header{
        font-size: 30px !important;
        text-align: center;
       
    }
    .tutorial_header{
        text-align: left !important;
        width: 60% !important;
        font-size: 13px !important;
        margin: 0 auto !important;
        padding-left: 1%;

    }
    .tutorial_header_second_one{
        
        width: 50% !important;
        font-size: 13px;
        margin-left: 21.5%;
        /* margin: 0 auto !important; */
        padding-left: 1%;
        margin-top: 2%;
    
    }
    .arrowtangled{
   
            height: 101px;
            margin-top: 8% !important;
            object-fit: contain;
            margin-left: 12%;
            transform: rotate(29deg) !important;
        }
        
    
    .iframe{
        margin-top:10% ;
        border-radius:20px;
        height: 200px;
    }
    .home_logo{
        float:none;
        width: 100px !important;
        height:100px !important;
      }
     .home-text{
        font-size: 2.5rem !important;
        text-align: center;
     } 
     .home_start_desktop_button{
        display: none;
     }
     .home-text-desc{
        text-align: center;
        font-size: 15px !important;
     }
     .home_start_mobile_button{
        display: block;
      }
      .home_image_banner{
        height: 350px;
      }
      .iframe{
        /* margin-top: 10%; */
      }
      .predictor_features_heading{
        font-size: 17px;
        font-weight: bold !important;
      }
      .predictor_features_desc{
        font-size: 14px;

      }
      .predictor_feature_button{
        width: 75% !important;
    }
   
    .predictor_feature{

        text-align: center !important;
        font-size: 30px !important;
        margin-bottom: 10%;
        margin-top: 5% !important;
        margin-left:0 !important;
    }
    .predictor_why_our_tool_desktop{
        display: none !important;
    }
    .predictor_why_our_tool_mobile{
        display: flex !important;
    }
   
  }


@media only screen and (max-width: 500px) 
    {
.how_it_works{
    font-size: 30px !important;
}
.features_secon_one{
    width: 50%;
}
        .slider-content {
            position: absolute;
            top: 0%;
            left: 5%;
        }
        .card-main {
            background: #f47523 !important;
            position: relative !important;
            top: 8px !important;
            height: 410px;
        }
        .slider-icon{
            font-size: 25px;
            position: relative;
            left: 15% !important;
            
        }
        .sliderheading
        {
            position: relative;
            top: -25px !important;
            font-size: 14px;
        }
        .slidercontent
        {
            position: relative;
            top:-23px !important;
            font-size: 12px;
        }
        .slider-button
        {
            position: absolute;
            top: 40%;
            left: -24px !important;
        
        }
        /* .sliderheading
        {
            position: relative;
            top: -20px !important;
        }
        

       .slider-button
       {
        position: relative;
        top:-40px;
       } */

    .padding-for-top
{
    padding: 0px !important;

}
.text-table
{
    font-size: 0.94rem !important;
}

}
.card-main
{
    background:#f47523 !important;
    position: relative;
    top:-50%;
    height: 410px;
    
}


.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -50px !important;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}
.carousel-indicators li {

    width: 22px;
    height: 3px;
    margin-right: 10px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: orange !important;
    background-clip: padding-box;
    border-top: 0px solid transparent;
    border-bottom: 5px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    
}  
.bg-orange
{
    background-color: #f47523 !important;
} 
#demo
{
    position: absolute;
    top:12%;
    width: 98%;

}
#demo-mobile
{
    display: none;
}
.testimonial_img
{
    width: 100px !important;
    height: 100px !important;
}
.sub-card
{
    position: relative;
    top: -30%;
    z-index: 2;
   
}
.card-height
{
    height:280px;
}
.slider-content
{
    position:absolute;
    top:30%;
    left:5%;
}
.more-btn {
    display: inline-block !important;
    background: rgba(255, 255, 255, 0.2) !important;
    padding: 6px 30px 8px 30px !important;
    color: #fff !important;
    border-radius: 50px !important;
    transition: all ease-in-out 0.4s !important;
}
.orange-color
{
    color:#f47523 !important;
}
.college_search_input{
    width:75%;
    margin-top:5% !important;
}

@media screen and (max-width: 768px) {


    .info-mobile
{
    padding-left: 15% !important;
}
    .our-services-desktop
    {
        display: none !important;
    }

.our-services-mobile
{
display:block !important;
}
    .college_search_input{
        width:60%;
        margin-top:1% !important;
        
    }
.college_searc_btn
{
    padding: 0.75rem 2.14rem;
    border-radius:20px;
    position:relative;
    left:-56px !important;
    background:#f47523 !important;
    color:white;
}
    .sub-card {
        position: relative;
        top: 3%;
        z-index: 2;
    }
    #demo-mobile
    {
        display: block;
        /* position: relative;
        top: -143px; */
    }
    #demo
    {
        display: none;
    }
    .home_banner_content h1 {
        font-size: 24px;
    }
    .home_banner_content h4 {
        font-size: 16px;
    }
    .home_search_group {
        margin-top: 20px !important;
    }
    .home_search_group .input-group-text {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .home_service_container {
        margin-top: 20px !important;
    }
}
